import React, { useState, useEffect } from 'react';
import '../../styles/Services.css';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080'; // Environment-aware

const Services = () => {
  // State for Specific Question Configuration
  const [specificQuestionConfig, setSpecificQuestionConfig] = useState({
    question: '',
    type: 'dropdown', // Default to dropdown-based input
    options: [], // For dropdown values
    rate: 0, // For rate-based value
  });
  const [questionType, setQuestionType] = useState('dropdown'); // Toggle between "dropdown" and "rate"
  const [questionError, setQuestionError] = useState(null);

  // State for Services
  const [services, setServices] = useState([]); // Default to an empty array
  const [newService, setNewService] = useState({
    serviceName: '',
    duration: 30, // Default to 30 minutes
    price: 0,
  });
  const [editingService, setEditingService] = useState(null); // For editing services

  // State for Distance Pricing
  const [pricePerMile, setPricePerMile] = useState(0);

  // General Error Handling
  const [error, setError] = useState(null);

  // State for Script Generator
  const [businessId, setBusinessId] = useState('');

  // Fetch data on initial load
  useEffect(() => {
    fetchSpecificQuestionConfig();
    fetchServices();
    fetchDistancePricing();
    fetchBusinessDetails();
  }, []);


  /** Fetch Specific Question Configuration */
  const fetchSpecificQuestionConfig = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/business-dashboard/settings/specific-question`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (!response.ok) throw new Error('Failed to fetch specific question configuration');
      const data = await response.json();
      setSpecificQuestionConfig(data || { question: '', type: 'dropdown', options: [], rate: 0 });
      setQuestionType(data?.type || 'dropdown');
    } catch (err) {
      setError(err.message);
    }
  };

  /** Fetch Business Details */
  const fetchBusinessDetails = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/business-dashboard/business-details`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (!response.ok) throw new Error('Failed to fetch business details');
      const data = await response.json();
      setBusinessId(data.businessId || '');
    } catch (err) {
      setError(err.message);
    }
  };

  /** Update Specific Question Configuration */
  const handleUpdateSpecificQuestion = async () => {
    if (!specificQuestionConfig.question) {
      setQuestionError('Question cannot be empty.');
      return;
    }
    setQuestionError(null); // Clear error

    try {
      const response = await fetch(`${API_BASE_URL}/api/business-dashboard/settings/update-specific-question`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(specificQuestionConfig),
      });

      if (!response.ok) throw new Error('Failed to update specific question configuration');
      alert('Specific question configuration updated successfully');
    } catch (err) {
      setError(err.message);
    }
  };

  /** Add Dropdown Option */
  const addDropdownOption = () => {
    setSpecificQuestionConfig({
      ...specificQuestionConfig,
      options: [...specificQuestionConfig.options, { text: '', value: 0 }],
    });
  };

  /** Update Dropdown Option */
  const updateDropdownOption = (index, field, value) => {
    const updatedOptions = [...specificQuestionConfig.options];
    updatedOptions[index][field] = value;
    setSpecificQuestionConfig({ ...specificQuestionConfig, options: updatedOptions });
  };

  /** Delete Dropdown Option */
  const deleteDropdownOption = (index) => {
    const updatedOptions = [...specificQuestionConfig.options];
    updatedOptions.splice(index, 1);
    setSpecificQuestionConfig({ ...specificQuestionConfig, options: updatedOptions });
  };

  /** Toggle Question Type */
  const toggleQuestionType = (type) => {
    setQuestionType(type);
    setSpecificQuestionConfig({ ...specificQuestionConfig, type, options: [], rate: 0 }); // Reset the config for the new type
  };

  /** Fetch Services */
  const fetchServices = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/business-dashboard/services`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (!response.ok) throw new Error('Failed to fetch services');
      const data = await response.json();
      setServices(data.services || []); // Ensure it defaults to an empty array
    } catch (err) {
      setError(err.message);
    }
  };

  /** Add New Service */
  const handleAddService = async (e) => {
    e.preventDefault();
    if (!newService.serviceName || !newService.duration || newService.price === undefined) {
      setError('Please provide a service name, duration, and price.');
      return;
    }
    try {
      const response = await fetch(`${API_BASE_URL}/api/business-dashboard/service/add`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newService),
      });

      if (!response.ok) throw new Error('Failed to add service');
      await fetchServices();
      setNewService({ serviceName: '', duration: 30, price: 0 });
    } catch (err) {
      setError(err.message);
    }
  };

  /** Edit Service */
  const handleEditService = async (service) => {
    setEditingService(service);
  };

  /** Save Edited Service */
  const saveEditedService = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/business-dashboard/service/edit/${editingService._id}`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(editingService),
      });

      if (!response.ok) throw new Error('Failed to update service');
      setEditingService(null);
      await fetchServices();
    } catch (err) {
      setError(err.message);
    }
  };

  /** Delete Service */
  const handleDeleteService = async (serviceId) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/business-dashboard/service/delete/${serviceId}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (!response.ok) throw new Error('Failed to delete service');
      await fetchServices();
    } catch (err) {
      setError(err.message);
    }
  };

  /** Fetch Distance Pricing */
  const fetchDistancePricing = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/business-dashboard/settings/distance-pricing`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (!response.ok) throw new Error('Failed to fetch distance pricing');
      const data = await response.json();
      setPricePerMile(data.pricePerMile || 0); // Default to 0 if undefined
    } catch (err) {
      setError(err.message);
    }
  };

  /** Update Distance Pricing */
  const handleUpdatePricing = async () => {
    if (pricePerMile <= 0) {
      setError('Distance pricing rate must be greater than 0.');
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/api/business-dashboard/settings/update-distance-pricing`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ pricePerMile }),
      });

      if (!response.ok) throw new Error('Failed to update distance pricing');
      alert('Distance pricing updated successfully');
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div className="services-page">
      {/* Script Generator Section */}
      <div className="script-generator">
        <h2>Embed Code</h2>
        <p>
          Copy and paste the following script into your website's HTML to include the pop-up for booking.
        </p>
        {businessId ? (
          <textarea
            readOnly
            value={`<script src="http://www.qwotly.com/embed.js?businessId=${businessId}"></script>`}
            style={{ width: '100%', height: '80px', marginTop: '10px', padding: '10px', fontSize: '14px' }}
          />
        ) : (
          <p>Loading business details...</p>
        )}
        {error && <p className="error-message">{error}</p>}
      </div>

      {/* Specific Question Section */}
      <div className="specific-question-config">
        <h2>Specific Question Configuration</h2>
        <div className="toggle-container">
          <span className={questionType === 'dropdown' ? 'active' : ''}>Dropdown Options</span>
          <div className={`toggle-switch ${questionType === 'rate' ? 'rate' : 'dropdown'}`} onClick={() => toggleQuestionType(questionType === 'dropdown' ? 'rate' : 'dropdown')}>
            <div className="toggle-circle"></div>
          </div>
          <span className={questionType === 'rate' ? 'active' : ''}>Rate Based</span>
        </div>
        <label>
          Question:
          <input type="text" placeholder="e.g., How big is your car?" value={specificQuestionConfig.question} onChange={(e) => setSpecificQuestionConfig({ ...specificQuestionConfig, question: e.target.value })} />
        </label>
        {questionError && <p className="error-message">{questionError}</p>}
        {questionType === 'dropdown' ? (
          <div className="dropdown-config">
            {specificQuestionConfig.options?.map((option, index) => (
              <div key={index} className="dropdown-option">
                <input type="text" placeholder="Option Text" value={option.text || ''} onChange={(e) => updateDropdownOption(index, 'text', e.target.value)} />
                <input type="number" placeholder="Value" value={option.value || 0} onChange={(e) => updateDropdownOption(index, 'value', e.target.value)} />
                <button onClick={() => deleteDropdownOption(index)}>Delete</button>
              </div>
            ))}
            <button onClick={addDropdownOption}>Add Option</button>
          </div>
        ) : (
          <div className="rate-config">
            <label>
              Rate per unit:
              <input type="number" placeholder="Rate per unit" value={specificQuestionConfig.rate || 0} onChange={(e) => setSpecificQuestionConfig({ ...specificQuestionConfig, rate: e.target.value })} />
            </label>
          </div>
        )}
        <button onClick={handleUpdateSpecificQuestion}>Update Question</button>
      </div>

      {/* Services Section */}
      <div className="services-section">
        <h2>Services</h2>
        <form onSubmit={handleAddService}>
          <input type="text" placeholder="Service Name" value={newService.serviceName} onChange={(e) => setNewService({ ...newService, serviceName: e.target.value })} />
          <input type="number" placeholder="Duration (minutes)" value={newService.duration} onChange={(e) => setNewService({ ...newService, duration: e.target.value })} />
          <input type="number" placeholder="Price ($)" value={newService.price} onChange={(e) => setNewService({ ...newService, price: e.target.value })} />
          <button type="submit">Add Service</button>
        </form>
        <ul>
          {services.map((service) => (
            <li key={service._id}>
              <span>{service.serviceName} - {service.duration} mins - ${service.price}</span>
              <button onClick={() => handleEditService(service)}>Edit</button>
              <button onClick={() => handleDeleteService(service._id)}>Delete</button>
            </li>
          ))}
        </ul>
        {editingService && (
          <div>
            <input type="text" value={editingService.serviceName} onChange={(e) => setEditingService({ ...editingService, serviceName: e.target.value })} />
            <input type="number" value={editingService.duration} onChange={(e) => setEditingService({ ...editingService, duration: e.target.value })} />
            <input type="number" value={editingService.price} onChange={(e) => setEditingService({ ...editingService, price: e.target.value })} />
            <button onClick={saveEditedService}>Save</button>
          </div>
        )}
      </div>

      {/* Distance Pricing Section */}
      <div className="distance-pricing">
        <h2>Distance Pricing</h2>
        <label>
          Rate per mile:
          <input type="number" value={pricePerMile} onChange={(e) => setPricePerMile(e.target.value)} />
        </label>
        <button onClick={handleUpdatePricing}>Update Distance Pricing</button>
      </div>
    </div>
  );
};

export default Services;
