// src/pages/Dashboard/Dashboard.js
import React from 'react';
import { Outlet } from 'react-router-dom';
import Navbar from '../../components/Navbar';

const Dashboard = () => (
  <div>
    <Navbar />
    <main>
      <Outlet /> {/* Nested routes like Overview, Calendar, etc., will render here */}
    </main>
  </div>
);

export default Dashboard;
