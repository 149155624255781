// src/pages/Dashboard/Bookings.js
import React, { useState, useEffect } from 'react';
import '../../styles/Booking.css';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080'; // Environment-aware

const Bookings = () => {
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filter, setFilter] = useState('all'); // all, pending, approved, completed, cancelled

  useEffect(() => {
    fetchBookings();
  }, []);

  const fetchBookings = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/business-dashboard/bookings`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (!response.ok) throw new Error('Failed to fetch bookings');
      const data = await response.json();
      setBookings(data.bookings);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleApprove = async (bookingId) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/business-dashboard/booking/approve/${bookingId}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (!response.ok) throw new Error('Failed to approve booking');
      fetchBookings(); // Refresh bookings list
    } catch (err) {
      setError(err.message);
    }
  };

  const handleDecline = async (bookingId) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/business-dashboard/booking/decline/${bookingId}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (!response.ok) throw new Error('Failed to decline booking');
      fetchBookings(); // Refresh bookings list
    } catch (err) {
      setError(err.message);
    }
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(amount || 0);
  };

  const getStatusColor = (status) => {
    const colors = {
      'pending': '#f59e0b',
      'approved': '#10b981',
      'completed': '#3b82f6',
      'cancelled': '#ef4444'
    };
    return colors[status?.toLowerCase()] || '#6b7280';
  };

  const filteredBookings = bookings.filter(booking => {
    if (filter === 'all') return true;
    return booking.status?.toLowerCase() === filter;
  });

  if (loading) return <div className="bookings-loading">Loading...</div>;

  return (
    <div className="bookings-page">
      <div className="bookings-header">
        <h2>Bookings Management</h2>
        <div className="filter-buttons">
          <button 
            className={filter === 'all' ? 'active' : ''} 
            onClick={() => setFilter('all')}
          >
            All
          </button>
          <button 
            className={filter === 'pending' ? 'active' : ''} 
            onClick={() => setFilter('pending')}
          >
            Pending
          </button>
          <button 
            className={filter === 'approved' ? 'active' : ''} 
            onClick={() => setFilter('approved')}
          >
            Approved
          </button>
          <button 
            className={filter === 'completed' ? 'active' : ''} 
            onClick={() => setFilter('completed')}
          >
            Completed
          </button>
          <button 
            className={filter === 'cancelled' ? 'active' : ''} 
            onClick={() => setFilter('cancelled')}
          >
            Cancelled
          </button>
        </div>
      </div>

      {error && <div className="error-message">{error}</div>}

      <div className="bookings-grid">
        {filteredBookings.map((booking) => (
          <div key={booking._id} className="booking-card">
            <div className="booking-header">
              <h3>{booking.serviceName}</h3>
              <span 
                className="booking-status"
                style={{ backgroundColor: getStatusColor(booking.status) }}
              >
                {booking.status}
              </span>
            </div>
            <div className="booking-details">
              <p><strong>Customer:</strong> {booking.firstName} {booking.lastName}</p>
              <p><strong>Email:</strong> {booking.customerEmail}</p>
              <p><strong>Phone:</strong> {booking.phoneNumber}</p>
              <p><strong>Service:</strong> {booking.serviceName}</p>
              <p><strong>Car Type:</strong> {booking.carBodyType}</p>
              <p><strong>Price:</strong> {formatCurrency(booking.totalQuote)}</p>
              <p><strong>Address:</strong> {booking.customerAddress}</p>
              <p><strong>Date:</strong> {new Date(booking.dateTime).toLocaleDateString()}</p>
              <p><strong>Time:</strong> {new Date(booking.dateTime).toLocaleTimeString()}</p>
            </div>
            {booking.status === 'pending' && (
              <div className="booking-actions">
                <button 
                  className="approve-button"
                  onClick={() => handleApprove(booking._id)}
                >
                  Approve
                </button>
                <button 
                  className="decline-button"
                  onClick={() => handleDecline(booking._id)}
                >
                  Decline
                </button>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Bookings;
