import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Step1.css';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const Step1 = () => {
  const [businessName, setBusinessName] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleNext = async () => {
    setError(null);
    setLoading(true);

    const token = localStorage.getItem('token');
    console.log('Token retrieved:', token);

    if (!token) {
      setError('User not authenticated. Please log in again.');
      setLoading(false);
      return;
    }

    try {
      console.log(`Submitting business name: ${businessName}`);
      const response = await fetch(`${API_BASE_URL}/api/onboarding/step1`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name: businessName }),
      });

      if (response.ok) {
        console.log('Business name saved successfully!');
        navigate('/onboarding/step2');
      } else {
        const errorData = await response.json();
        console.error('Error from server:', errorData);
        setError(errorData.error || 'Failed to save business name.');
      }
    } catch (err) {
      console.error('Error saving business name:', err);
      setError('Something went wrong. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h2>Step 1: Basic Setup</h2>
      <div>
        <h3>What do you want to name your business?</h3>
        {error && <p className="error-message">{error}</p>}
        <div className="form-group">
          <label htmlFor="businessName">Business Name</label>
          <input
            type="text"
            id="businessName"
            value={businessName}
            onChange={(e) => setBusinessName(e.target.value)}
            placeholder="Enter your business name"
            required
          />
        </div>
      </div>
      <button onClick={handleNext} disabled={!businessName || loading}>
        {loading ? 'Saving...' : 'Next'}
      </button>
    </div>
  );
};

export default Step1;

