import React, { useState, useEffect } from 'react';

function Reschedule() {
  const [slots, setSlots] = useState([]);
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedSlot, setSelectedSlot] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const token = new URLSearchParams(window.location.search).get('token');
  const businessId = new URLSearchParams(window.location.search).get('businessId');
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080'; // Environment-aware

  useEffect(() => {
    if (!token || !businessId) {
      setError('Invalid reschedule link. Please try again.');
    }
  }, [token, businessId]);

  const fetchSlots = async (date) => {
    setLoading(true);
    setError('');
    try {
      const response = await fetch(`${API_BASE_URL}/api/customer/get-available-slots?businessId=${businessId}&date=${date}`);
      const data = await response.json();
      setSlots(data.slots || []);
    } catch (err) {
      setError('Error loading slots. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
    fetchSlots(e.target.value);
  };

  const handleReschedule = async () => {
    if (!selectedSlot) {
      alert('Please select a time slot.');
      return;
    }

    const newDateTime = `${selectedDate}T${selectedSlot}`;
    try {
      const response = await fetch(`${API_BASE_URL}/api/customer/reschedule`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token, newDateTime }),
      });

      if (response.ok) {
        alert('Your booking has been successfully rescheduled.');
        window.location.href = '/'; // Redirect to homepage
      } else {
        const data = await response.json();
        alert(`Error: ${data.error || 'Failed to reschedule booking.'}`);
      }
    } catch (err) {
      alert('An error occurred while rescheduling. Please try again.');
    }
  };

  return (
    <div style={{ fontFamily: 'Arial, sans-serif', textAlign: 'center', padding: '20px' }}>
      <h1>Reschedule Your Booking</h1>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {!error && (
        <>
          <label>
            Select Booking Date:
            <input
              type="date"
              value={selectedDate}
              onChange={handleDateChange}
              style={{ display: 'block', margin: '10px auto' }}
            />
          </label>
          {loading ? (
            <p>Loading slots...</p>
          ) : (
            <div style={{ marginTop: '20px', display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(80px, 1fr))', gap: '10px' }}>
              {slots.map((slot) => (
                <button
                  key={slot.time}
                  onClick={() => setSelectedSlot(slot.time)}
                  disabled={!slot.available}
                  style={{
                    padding: '8px',
                    border: '1px solid #ccc',
                    borderRadius: '5px',
                    backgroundColor: slot.available ? '#f9f9f9' : 'grey',
                    cursor: slot.available ? 'pointer' : 'not-allowed',
                  }}
                >
                  {slot.time}
                </button>
              ))}
            </div>
          )}
          <button
            onClick={handleReschedule}
            style={{
              marginTop: '20px',
              padding: '10px 20px',
              backgroundColor: '#007bff',
              color: '#fff',
              border: 'none',
              cursor: 'pointer',
              width: '200px',
            }}
          >
            Confirm Reschedule
          </button>
        </>
      )}
    </div>
  );
}

export default Reschedule;
