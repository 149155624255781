import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080'; // Environment-aware

const Step2 = () => {
  const [niche, setNiche] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const businessNiches = [
    { id: 'car-detailing', title: 'Car Detailing', icon: '🚗' },
    { id: 'roof-cleaning', title: 'Roof Cleaning', icon: '🏠' },
    { id: 'pool-cleaning', title: 'Pool Cleaning', icon: '🏊‍♂️' },
  ];

  const handleNext = async () => {
    setError(null);
    setLoading(true);
  
    try {
      const response = await fetch(`${API_BASE_URL}/api/onboarding/step2`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ niche }),
      });
  
      if (response.ok) {
        localStorage.setItem('niche', niche); // Store the niche in local storage
        console.log(`Niche "${niche}" saved in local storage.`);
        navigate('/onboarding/step3'); // Move to Step 3
      } else {
        const errorData = await response.json();
        setError(errorData.error || 'Failed to save niche.');
      }
    } catch (err) {
      console.error('Error saving niche:', err);
      setError('Something went wrong. Please try again.');
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <div>
      <h2>Step 2: Select Your Niche</h2>
      <div>
        <h3>What is your business niche?</h3>
        {error && <p className="error-message">{error}</p>}
        <div className="niche-grid">
          {businessNiches.map((businessNiche) => (
            <div
              key={businessNiche.id}
              className={`niche-box ${niche === businessNiche.id ? 'selected' : ''}`}
              onClick={() => setNiche(businessNiche.id)}
            >
              <div className="niche-icon">{businessNiche.icon}</div>
              <h3>{businessNiche.title}</h3>
            </div>
          ))}
        </div>
      </div>
      <button onClick={handleNext} disabled={!niche || loading}>
        {loading ? 'Saving...' : 'Next'}
      </button>
    </div>
  );
};

export default Step2;
