// src/pages/Dashboard/Overview.js
import React, { useState, useEffect } from 'react';
import '../../styles/DashboardOverview.css';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080'; // Environment-aware

const Overview = () => {
  const [todayBookings, setTodayBookings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        // Fetch today's bookings
        const todayResponse = await fetch(`${API_BASE_URL}/api/business-dashboard/bookings/today`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });

        if (!todayResponse.ok) {
          throw new Error('Failed to fetch dashboard data');
        }

        const todayData = await todayResponse.json();
        setTodayBookings(todayData.bookings || []);
        console.log('Today Bookings:', todayData);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchDashboardData();
  }, []);

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(amount || 0);
  };

  const getStatusColor = (status) => {
    const colors = {
      'pending': '#f59e0b',
      'approved': '#10b981',
      'completed': '#3b82f6',
      'cancelled': '#ef4444'
    };
    return colors[status?.toLowerCase()] || '#6b7280';
  };

  if (loading) return <div className="dashboard-loading">Loading...</div>;
  if (error) return <div className="dashboard-error">Error: {error}</div>;

  // Calculate summary stats from todayBookings
  const pendingCount = todayBookings.filter(booking => booking.status === 'pending').length;
  const completedCount = todayBookings.filter(booking => booking.status === 'completed').length;
  const todayRevenue = todayBookings.reduce((sum, booking) => 
    booking.status !== 'cancelled' ? sum + (booking.totalQuote || 0) : sum, 0
  );

  return (
    <div className="dashboard-overview">
      {/* Summary Stats */}
      <div className="stats-grid">
        <div className="stat-card">
          <h3>Today's Bookings</h3>
          <p className="stat-value">{todayBookings.length}</p>
        </div>
        <div className="stat-card">
          <h3>Pending Approvals</h3>
          <p className="stat-value">{pendingCount}</p>
        </div>
        <div className="stat-card">
          <h3>Today's Revenue</h3>
          <p className="stat-value">{formatCurrency(todayRevenue)}</p>
        </div>
        <div className="stat-card">
          <h3>Completed Today</h3>
          <p className="stat-value">{completedCount}</p>
        </div>
      </div>

      {/* Today's Bookings */}
      <div className="todays-bookings">
        <h2>Today's Bookings</h2>
        {todayBookings.length === 0 ? (
          <p className="no-bookings">No bookings scheduled for today</p>
        ) : (
          <div className="bookings-grid">
            {todayBookings.map((booking) => (
              <div key={booking._id} className="booking-card">
                <div className="booking-header">
                  <h3>{booking.serviceName}</h3>
                  <span 
                    className="booking-status"
                    style={{ backgroundColor: getStatusColor(booking.status) }}
                  >
                    {booking.status}
                  </span>
                </div>
                <div className="booking-details">
                  <p>
                    <strong>Customer:</strong> {booking.firstName} {booking.lastName}
                  </p>
                  <p>
                    <strong>Time:</strong> {booking.bookingTime?.startTime} - {booking.bookingTime?.endTime}
                  </p>
                  <p>
                    <strong>Price:</strong> {formatCurrency(booking.totalQuote)}
                  </p>
                  {booking.customerAddress && (
                    <p>
                      <strong>Address:</strong> {booking.customerAddress}
                    </p>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Overview;
