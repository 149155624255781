import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import '../../styles/Calendar.css';
import BlockTimeModal from '../../components/BlockTimeModal';

const Calendar = () => {
  const [events, setEvents] = useState([]);
  const [availability, setAvailability] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showBlockModal, setShowBlockModal] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080'; // Environment-aware

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch bookings
        const bookingsResponse = await fetch(`${API_BASE_URL}/api/business-dashboard/bookings`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });
        if (!bookingsResponse.ok) throw new Error('Failed to fetch bookings');
        const bookingsData = await bookingsResponse.json();
        
        // Fetch blocked times
        const blockedTimesResponse = await fetch(`${API_BASE_URL}/api/business-dashboard/blocked-times`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });
        if (!blockedTimesResponse.ok) throw new Error('Failed to fetch blocked times');
        const blockedTimesData = await blockedTimesResponse.json();

        // Map bookings to events
        const bookingEvents = bookingsData.bookings.map((booking) => ({
          id: booking._id,
          title: `${booking.firstName} ${booking.lastName}: ${booking.serviceName}`,
          start: `${booking.bookingTime.day}T${booking.bookingTime.startTime}`,
          end: `${booking.bookingTime.day}T${booking.bookingTime.endTime}`,
          extendedProps: {
            type: 'booking',
            customerAddress: booking.customerAddress,
            price: booking.totalQuote,
          },
        }));

        // Map blocked times to events
        const blockedEvents = blockedTimesData.map((block) => ({
          id: block._id,
          title: block.title,
          start: new Date(block.startTime).toISOString(),
          end: new Date(block.endTime).toISOString(),
          backgroundColor: '#808080',
          classNames: ['blocked-time'],
          extendedProps: {
            type: 'blocked',
            reason: block.reason
          }
        }));

        // Combine both types of events
        setEvents([...bookingEvents, ...blockedEvents]);

        // Fetch availability
        const availabilityResponse = await fetch(`${API_BASE_URL}/api/business-dashboard/availability`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });
        if (!availabilityResponse.ok) throw new Error('Failed to fetch availability');
        const availabilityData = await availabilityResponse.json();
        setAvailability(availabilityData);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const mapAvailabilityToBusinessHours = (availability) => {
    const daysMap = {
      Monday: 1,
      Tuesday: 2,
      Wednesday: 3,
      Thursday: 4,
      Friday: 5,
      Saturday: 6,
      Sunday: 0,
    };

    return availability.map((item) => ({
      daysOfWeek: [daysMap[item.day]], // Map day names to numeric days
      startTime: item.startTime,
      endTime: item.endTime,
    }));
  };

  const businessHours = mapAvailabilityToBusinessHours(availability);

  const handleEventDrop = async (info) => {
    const { event } = info;
    const eventType = event.extendedProps?.type;

    try {
      if (eventType === 'blocked') {
        // Handle blocked time reschedule
        const response = await fetch(`${API_BASE_URL}/api/business-dashboard/blocked-time/${event.id}/reschedule`, {
          method: 'PUT',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            startTime: event.start.toISOString(),
            endTime: event.end.toISOString(),
          }),
        });

        if (!response.ok) {
          throw new Error('Failed to reschedule blocked time');
        }
      } else {
        // Handle regular booking reschedule
        const newStartDate = event.start;
        const formattedDate = newStartDate.toISOString().split('T')[0];
        const formattedTime = newStartDate.toTimeString().split(' ')[0].slice(0, 5);

        const response = await fetch(`${API_BASE_URL}/api/business-dashboard/booking/reschedule`, {
          method: 'PUT',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            bookingId: event.id,
            newTime: {
              day: formattedDate,
              startTime: formattedTime
            },
            requestor: 'business',
          }),
        });

        if (!response.ok) {
          throw new Error('Failed to reschedule booking');
        }
      }
    } catch (err) {
      console.error('Error rescheduling:', err);
      info.revert();
      alert(err.message);
    }
  };

  const handleDateSelect = (selectInfo) => {
    setSelectedSlot(selectInfo.startStr);
    setShowBlockModal(true);
  };

  const handleBlockTimeSave = async (blockData) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/business-dashboard/blocked-time`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          title: blockData.title,
          reason: blockData.reason,
          startTime: new Date(blockData.startTime).toISOString(),
          endTime: new Date(blockData.endTime).toISOString(),
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to save blocked time');
      }
      
      const savedBlock = await response.json();
      
      // Add the new blocked time to events
      setEvents(prev => [...prev, {
        id: savedBlock._id,
        title: savedBlock.title,
        start: savedBlock.startTime,
        end: savedBlock.endTime,
        backgroundColor: '#808080',
        classNames: ['blocked-time'],
        extendedProps: {
          type: 'blocked',
          reason: savedBlock.reason
        }
      }]);

      setShowBlockModal(false);
    } catch (err) {
      console.error('Error saving blocked time:', err);
    }
  };

  return (
    <div className="calendar-page">
      {loading && <p>Loading...</p>}
      {error && <p>Error: {error}</p>}
      {!loading && !error && (
        <FullCalendar
          plugins={[
            timeGridPlugin,
            interactionPlugin,
            dayGridPlugin,
          ]}
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'timeGridDay,timeGridWeek,dayGridMonth'
          }}
          views={{
            timeGridDay: { // Day view
              titleFormat: { month: 'long', day: 'numeric', year: 'numeric' }
            },
            timeGridWeek: { // Week view
              titleFormat: { month: 'long', year: 'numeric' }
            },
            dayGridMonth: { // Month view
              titleFormat: { month: 'long', year: 'numeric' }
            }
          }}
          initialView="timeGridWeek"
          editable={true}
          events={events}
          businessHours={businessHours}
          firstDay={1} // Start the week on Monday
          eventConstraint={{
            businessHours: true, // Prevent moving events outside business hours
          }}
          eventDrop={handleEventDrop} // Handle drag-and-drop
          slotMinTime="00:00:00"
          slotMaxTime="24:00:00"
          slotDuration="00:30:00"
          slotLabelContent={(arg) => {
            if (arg.date.getMinutes() === 0) {
              return <div className="slot-hour-label">{`${arg.date.getHours()}:00`}</div>;
            } else {
              return <div className="slot-half-hour-label">{`${arg.date.getHours()}:${arg.date.getMinutes()}`}</div>;
            }
          }}
          selectable={true}
          select={handleDateSelect}
        />
      )}
      <BlockTimeModal
        isOpen={showBlockModal}
        onClose={() => setShowBlockModal(false)}
        selectedTime={selectedSlot}
        onSave={handleBlockTimeSave}
      />
    </div>
  );
};

export default Calendar;
