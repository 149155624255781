import React, { useState, useEffect } from 'react';
import '../styles/BlockTimeModal.css';

const BlockTimeModal = ({ isOpen, onClose, selectedTime, onSave }) => {
  const [blockData, setBlockData] = useState({
    title: '',
    reason: 'custom',
    startTime: '',
    endTime: ''
  });

  useEffect(() => {
    if (selectedTime) {
      const startDateTime = new Date(selectedTime);
      const endDateTime = new Date(startDateTime.getTime() + 30 * 60000);
      
      setBlockData({
        ...blockData,
        startTime: startDateTime.toISOString(),
        endTime: endDateTime.toISOString()
      });
    }
  }, [selectedTime]);

  const predefinedReasons = [
    { value: 'lunch', label: 'Lunch Break' },
    { value: 'meeting', label: 'Meeting' },
    { value: 'offline', label: 'Off-platform Appointment' },
    { value: 'custom', label: 'Custom' }
  ];

  const generateTimeOptions = (baseTime) => {
    if (!baseTime) return [];
    
    const times = [];
    const start = new Date(baseTime);
    const end = new Date(start);
    end.setHours(23, 59, 0, 0);

    let current = new Date(start);
    while (current <= end) {
      times.push(current.toISOString());
      current = new Date(current.getTime() + 30 * 60000);
    }
    return times;
  };

  const handleSave = () => {
    if (!blockData.startTime || !blockData.endTime) return;
    
    onSave({
      ...blockData,
      title: blockData.title || 'Blocked Time'
    });
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="block-time-modal-overlay">
      <div className="block-time-modal">
        <h2>Block Time Slot</h2>
        <div className="form-group">
          <label>Reason</label>
          <select 
            value={blockData.reason || 'custom'}
            onChange={(e) => setBlockData({ 
              ...blockData, 
              reason: e.target.value,
              title: e.target.value !== 'custom' ? 
                predefinedReasons.find(r => r.value === e.target.value).label : 
                blockData.title 
            })}
          >
            {predefinedReasons.map(reason => (
              <option key={reason.value} value={reason.value}>
                {reason.label}
              </option>
            ))}
          </select>
        </div>

        {blockData.reason === 'custom' && (
          <div className="form-group">
            <label>Custom Title (Optional)</label>
            <input
              type="text"
              value={blockData.title || ''}
              onChange={(e) => setBlockData({ ...blockData, title: e.target.value })}
              placeholder="Blocked Time"
            />
          </div>
        )}

        <div className="form-group">
          <label>Start Time</label>
          <select
            value={blockData.startTime || ''}
            onChange={(e) => setBlockData({ ...blockData, startTime: e.target.value })}
          >
            {generateTimeOptions(selectedTime).map(time => (
              <option key={time} value={time}>
                {new Date(time).toLocaleTimeString()}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label>End Time</label>
          <select
            value={blockData.endTime || ''}
            onChange={(e) => setBlockData({ ...blockData, endTime: e.target.value })}
          >
            {generateTimeOptions(blockData.startTime).map(time => (
              <option key={time} value={time}>
                {new Date(time).toLocaleTimeString()}
              </option>
            ))}
          </select>
        </div>

        <div className="modal-actions">
          <button onClick={handleSave}>Save</button>
          <button onClick={onClose}>Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default BlockTimeModal; 