// src/components/Navbar.js
import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Navbar.css';

const Navbar = () => (
  <nav className="navbar">
    <Link to="/dashboard">Overview</Link>
    <Link to="/dashboard/calendar">Calendar</Link>
    <Link to="/dashboard/bookings">Bookings</Link>
    <Link to="/dashboard/services">Services</Link>
    <Link to="/dashboard/settings">Settings</Link>
  </nav>
);

export default Navbar;
