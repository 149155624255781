import React, { useState, useEffect } from 'react';

function Cancel() {
  const [status, setStatus] = useState('pending');
  const token = new URLSearchParams(window.location.search).get('token');
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080'; // Environment-aware

  useEffect(() => {
    if (!token) {
      setStatus('error');
    }
  }, [token]);

  const handleConfirmCancel = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/customer/confirm-cancel?token=${token}`, {
        method: 'DELETE',
      });

      if (response.ok) {
        setStatus('success');
      } else {
        const data = await response.json();
        setStatus(data.error || 'Error canceling booking.');
      }
    } catch (error) {
      console.error('Error confirming cancellation:', error);
      setStatus('error');
    }
  };

  return (
    <div style={{ fontFamily: 'Arial, sans-serif', textAlign: 'center', padding: '20px' }}>
      {status === 'pending' && (
        <>
          <h1>Are you sure you want to cancel your booking?</h1>
          <p>This action cannot be undone.</p>
          <button
            onClick={handleConfirmCancel}
            style={{
              padding: '10px 20px',
              backgroundColor: 'red',
              color: 'white',
              border: 'none',
              cursor: 'pointer',
            }}
          >
            Confirm Cancel
          </button>
        </>
      )}
      {status === 'success' && (
        <p style={{ color: 'green' }}>Your booking has been successfully canceled. Sorry to see you go!</p>
      )}
      {status === 'error' && (
        <p style={{ color: 'red' }}>An error occurred. Please try again later.</p>
      )}
    </div>
  );
}

export default Cancel;

